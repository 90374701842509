<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle label="Create New Material" align="center" />
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-25 md-xsmall-size-100 mb-3">
        <SelectComponent
          label="File Type"
          italic
          class="w-full"
          placeholder="IMAGE"
          v-model.trim="$v.form.fileType.$model"
          :message="!$v.form.fileType.required && $v.form.fileType.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100 mb-3">
        <InputFieldComponent
          italic
          label="File Name"
          v-model.trim="$v.form.fileName.$model"
          :message="!$v.form.fileName.required && $v.form.fileName.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-25 md-xsmall-size-100 mb-3">
        <SelectComponent
          label="File For"
          italic
          class="w-full"
          placeholder="RA, DI"
          v-model.trim="$v.form.fileFor.$model"
          :message="!$v.form.fileFor.required && $v.form.fileFor.$dirty ? 'Field is required' : null"
          />
      </div>
      
      <div class="md-layout-item md-size-55 md-xsmall-size-100 mb-3">
        <FileInputCompoment
          label="Choose Thumbnail"
          italic
          v-model.trim="$v.form.thumbnail.$model"
          :message="!$v.form.thumbnail.required && $v.form.thumbnail.$dirty ? 'Field is required' : null"
        />
      </div>      
      <div class="md-layout-item md-size-55 md-xsmall-size-100 mb-3">
        <FileInputCompoment
          label="Choose Material"
          italic
          v-model.trim="$v.form.material.$model"
          :message="!$v.form.material.required && $v.form.material.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-25 md-xsmall-size-100 flex align-center">
        <md-checkbox
          true-value="true"
          false-value="false"
          class="mt-6"
          v-model.trim="$v.form.isSpecial.$model"
        >Mark as special</md-checkbox>
        <span class="text-sm text-danger" v-if="!$v.form.isSpecial.required && $v.form.isSpecial.$dirty">Field is required</span>
      </div>
      <div class="md-layout-item md-size-85 md-xsmall-size-100 mb-3">
        <TextAreaComponent
          label="Notes"
          label-class="text-italic"
          v-model.trim="$v.form.notes.$model"
          :message="!$v.form.notes.required && $v.form.notes.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="flex align-center md-layout-item md-size-50 md-xsmall-size-100 mb-3">
        <md-checkbox
          true-value="true"
          false-value="false"
          v-model.trim="$v.form.isDownloadable.$model"
        >Access to download</md-checkbox>
        <span class="text-sm text-danger" v-if="!$v.form.isDownloadable.required && $v.form.isDownloadable.$dirty">Field is required</span>
      </div>

      <div class="flex justify-end align-center md-layout-item md-size-50 mb-3">
         <md-button class="md-default">Save As Draft</md-button>
         <md-button class="border border-solid border-gray-500 rounded">Schedule</md-button>
         <md-button type="submit" class="bg-victoria text-white">Create</md-button>
      </div>

    </div>
  </form>
</template>

<script>
import {
    DialogTitle,
    SelectComponent,
    TextAreaComponent,
    InputFieldComponent
  } from "@/components"
import FileInputCompoment from '@/components/atom/form/FileInputComponent';
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
    components: {
        DialogTitle,
        SelectComponent,
        TextAreaComponent,
        InputFieldComponent,
        FileInputCompoment
    },
    data() {
        return {
            value: true,
            form: {
              fileType: "",
              fileName: "",
              fileFor: "",
              thumbnail: "",
              material: "",
              isSpecial: "",
              notes: "",
              isDownloadable: "",
            }
        }
    },
    methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Material saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
        fileType: {required},
        fileName: {required},
        fileFor: {required},
        thumbnail: {required},
        material: {required},
        isSpecial: {required},
        notes: {required},
        isDownloadable: {required},
    }
  }
};
</script>

<style></style>
